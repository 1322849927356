import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import VideoEmbedded from '../../components/VideoEmbedded';

const UnderstandingStudy = () => {
  const {t} = useTranslation();
  const [loopTrigger, setLoopTrigger] = useState(0);
  const videoId = t('understanding_study.video_id');

  return (
    <section
      className={`py-10 
         md:py-12 
         lg:py-16 
         xl:py-24 
         bg-violetDark 
         md:bg-circles-video-bg 
         bg-no-repeat 
         bg-center 
         md:bg-full-circle-size-md 
         lg:bg-full-circle-size-lg 
         xl:bg-full-circle-size-xl`}
    >
      <h2 className="px-6 text-white mb-10 md:mb-9 lg:mb-10 xl:mb-12">
        {t('understanding_study.title')}
      </h2>
      {!loopTrigger ? (
        <VideoEmbedded embedId={videoId} setLoopTrigger={setLoopTrigger} loopTrigger={1} />
      ) : (
        <VideoEmbedded embedId={videoId + ' '} setLoopTrigger={setLoopTrigger} loopTrigger={0} />
      )}
    </section>
  );
};

export default UnderstandingStudy;
