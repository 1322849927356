import enUS from './enUS';

const noNO = {
  ...enUS,
  'welcome.title': 'Velkommen til FNAIT naturhistorisk studie',
  'welcome.content':
    'FNAIT naturhistorisk studie er en forskningsstudie for å få vite mer om fetal og neonatal alloimmun-trombocytopeni (FNAIT).',
  'welcome.cta': 'Lær mer om FNAIT',

  'what_is_study.title': 'Hva er FNAIT naturhistorisk studie?',
  'what_is_study.content':
    'FNAIT naturhistorisk studie blir gjennomført for å lære mer om FNAIT, en sjelden tilstand som påvirker fostre. FNAIT oppstår når morens immunsystem angriper fosterets blodplater, d blodcellene som hjelper til med å stanse blødninger. Legene tror dette skjer for 1 av 1000 fostre, men det virkelige tallet er ukjent da det trengs mer forskning.',
  'what_is_study.take-away':
    'Det er frivillig å være med i studien og du kan når som helst forlate studien. Din fremtidige medisinske pleie med din lege eller med institusjonen vil ikke påvirkes av din beslutning om å bli med på eller forlate studien.',

  'understanding_study.title': 'Å forstå FNAIT naturhistorisk studie',
  'understanding_study.video_id': 'aKYFpgtes_8',

  'why_is_study_done.title': 'Hvorfor gjennomføres denne studien?',
  'why_is_study_done.content_1':
    'Studien er del av en forskningsinnsats mot en fremtidig behandling for å forhindre at mødre og fostre blir påvirket av FNAIT.',
  'why_is_study_done.content_2':
    'Denne studien sponses av Rallybio, et biofarmasøytisk selskap som er engasjert i å identifisere og fremskynde utviklingen av livstransformerende behandlinger for pasienter med alvorlige og sjeldne lidelser.',

  'who_can_join.title': 'Hvem kan bli med i studien?',
  'who_can_join.content': 'Du kan bli med i studien hvis:',
  'who_can_join.list_item_1': 'Du er over 18 år.',
  'who_can_join.list_item_2':
    'Du gjennomfører et besøk i ukene 10-14 av svangerskapet ditt på en deltakende helseinstitusjon.',
  'who_can_join.list_item_3': 'Du ikke har hatt FNAIT i et tidligere svangerskap.',

  'what_do_i_do.title': 'Hva må jeg gjøre som del av studien?',
  'what_do_i_do.card_1':
    'Først må du signere <b>et skjema for informert samtykke<b>, som vil forklare deg alt om studien. Studielegen vil svare på alle spørsmål du måtte ha om informasjonen i skjemaet.',
  'what_do_i_do.card_2':
    'Deretter vil du tillate <b>en blodprøve<b> å tas mellom ukene 10 og 14.                                                                      ',
  'what_do_i_do.card_3':
    'Avhengig av resultatene, kan du bli bedt om å avgi <b>ytterligere blodprøver<b> ved ditt neste besøk under graviditeten og muligens 10 uker etter graviditeten.',
  'what_do_i_do.card_4':
    'Du vil også tillate at <b>informasjon om svangerskapet ditt<b> deles med forskerne. Dette inkluderer resultatene av blodprøven, utfallet av svangerskapet, og om barnet ditt hadde lave blodplatenivåer i blodet ved fødselen.',

  'will_cost_be_covered.title': 'Vil kostnadene med min helsepleie bli dekket som del av studien?',
  'will_cost_be_covered.content': 'Det er ingen kostnader for deg med å delta i denne studien.',

  'who_will_see_results.title': 'Hvem vil se testresultatene?',
  'who_will_see_results.content_1':
    'Resultatene av testen vil gjøres tilgjengelig for legen din, som vil kunne diskutere dem med deg.',
  'who_will_see_results.content_2':
    'Din identitet og testresultatene vil holdes konfidensielle i samsvar med lokal personvernlovgivning. En unik identifikator vil benyttes når vi sender inn dine  opplysninger til studiesponsoren.',

  'interested_in_learning.title': 'Interessert i å få vite mer om studien?',
  'interested_in_learning.take-away':
    'For mer informasjon om FNAIT naturhistorisk studie, snakk med legen din.',

  'footer.privacy_policy': 'Retningslinjer for personvern',
  'footer.terms_of_use': 'Bruksvilkår',
  'footer.disclaimer':
    'PPD - Rallybio FNAIT Natural History Study - Study Website - (08-Dec-2021) - Norwegian (Norway) - V2.0',
};

export default noNO;
