import listImage from '../../assets/images/list_image.svg';
import {useTranslation} from 'react-i18next';

const WhoCanJoin = () => {
  const {t} = useTranslation();

  return (
    <section className="container py-16 lg:py-20 xl:py-36">
      <h2 className="text-center font-extrabold md:text-left">{t('who_can_join.title')}</h2>
      <div className="bg-right bg-test md:bg-circles-size-md lg:bg-circles-size-lg xl:bg-circles-size-xl md:bg-circles-bg bg-no-repeat py-11">
        <p className="mb-8 text-center md:text-left lg:max-w-listWidthDesktop xl:max-w-listWidthLargeDesktop">
          {t('who_can_join.content')}
        </p>
        <ul>
          <li className="flex flex-row items-center mb-8 max-w-full xl:max-w-xl md:max-w-xl">
            <img
              className="w-listImageMobile md:w-listImageDesktop lg:w-listImageLargeDesktop"
              src={listImage}
              alt="List item first"
            />
            <p className="ml-4 lg:ml-6 md:max-w-xs lg:max-w-listWidthDesktop xl:max-w-listWidthLargeDesktop">
              {t('who_can_join.list_item_1')}
            </p>
          </li>
          <li className="flex flex-row items-center mb-8 max-w-full xl:max-w-xl md:max-w-xl">
            <img
              className="w-listImageMobile md:w-listImageDesktop lg:w-listImageLargeDesktop"
              src={listImage}
              alt="List item second"
            />
            <p className="ml-4 lg:ml-6 md:max-w-listWidthTablet lg:max-w-listWidthDesktop xl:max-w-listWidthLargeDesktop">
              {t('who_can_join.list_item_2')}
            </p>
          </li>
          <li className="flex flex-row items-center max-w-full xl:max-w-xl md:max-w-xl">
            <img
              className="w-listImageMobile md:w-listImageDesktop lg:w-listImageLargeDesktop"
              src={listImage}
              alt="List item third"
            />
            <p className="ml-4 lg:ml-6 md:max-w-listWidthTablet lg:max-w-listWidthDesktop xl:max-w-listWidthLargeDesktop">
              {t('who_can_join.list_item_3')}
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default WhoCanJoin;
