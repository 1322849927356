import React from 'react';
import {useTranslation} from 'react-i18next';

const InterestedInLearning = () => {
  const {t} = useTranslation();

  return (
    <section
      className={`pb-16 
        lg:pb-20 
        xl:pb-36 
        pt-56 
        md:pt-28 
        lg:pt-40 
        xl:pt-52 
        bg-violetLight
        bg-interestedInLearning-bg
        bg-no-repeat
        bg-interestedInLearning-bg-position
        md:bg-right-top
        lg:bg-top
        bg-cover
        `}
    >
      <div className="container flex flex-row items-center justify-center">
        <div className="ml-0 flex flex-col items-center md:ml-16 justify-center">
          <h2 className="text-center font-extrabold mb-10 lg:mb-14">
            {t('interested_in_learning.title')}
          </h2>

          <p className="bg-white p-5 rounded-3xl paragraph--2 text-center">
            {t('interested_in_learning.take-away')}
          </p>
        </div>
      </div>
    </section>
  );
};

export default InterestedInLearning;
