import enUS from './enUS';

const nlNL = {
  ...enUS,
  'welcome.title': 'Welkom bij het FNAIT Natuurlijk Beloop Onderzoek',
  'welcome.content':
    'Het FNAIT Natuurlijk Beloop Onderzoek is een onderzoek om meer te weten te komen over foetale en neonatale alloimmuun trombocytopenie (FNAIT).',
  'welcome.cta': 'Kom meer te weten over FNAIT',

  'what_is_study.title': 'Wat is het FNAIT Natuurlijk Beloop Onderzoek?',
  'what_is_study.content':
    'Het FNAIT Natuurlijk Beloop Onderzoek wordt uitgevoerd om meer te weten te komen over FNAIT, een zeldzame aandoening die baby’s in ontwikkeling treft. FNAIT vindt plaats wanneer het immuunsysteem van de moeder de bloedplaatjes – de bloedcellen die helpen bloedingen te stoppen – van de baby aanvalt. Artsen denken dat dit voorkomt bij 1 op de 1000 baby’s, maar het werkelijke aantal is onbekend. Hiervoor is meer onderzoek nodig. Om meer te weten over FNAIT worden zwangere vrouwen gezocht die een hoger risico op FNAIT hebben. Omdat FNAIT zo weinig voorkomt, zijn er duizenden zwangere vrouwen nodig die willen meedoen aan een bloedonderzoek, om een klein aantal vrouwen te vinden met een hoger risico op FNAIT.',
  'what_is_study.take-away':
    'Deelname aan het onderzoek is vrijwillig en u kunt het onderzoek op ieder moment verlaten. Uw besluit om deel te nemen aan het onderzoek, of om het onderzoek te verlaten, heeft geen invloed op uw toekomstige medische zorg door uw arts of instelling.',

  'understanding_study.title': 'Inzicht in het FNAIT Natuurlijk Beloop Onderzoek',
  'understanding_study.video_id': 'bGDj9aLHyzo',

  'why_is_study_done.title': 'Waarom vindt dit onderzoek plaats?',
  'why_is_study_done.content_1':
    'Het onderzoek maakt deel uit van een onderzoek naar een toekomstige behandeling die voorkomt dat moeders en baby’s getroffen worden door FNAIT.',
  'why_is_study_done.content_2':
    'Dit onderzoek wordt betaald door Rallybio, een biofarmaceutisch bedrijf dat zich richt op het identificeren en bespoedigen van de ontwikkeling van levensveranderende behandelingen voor patiënten met ernstige en zeldzame aandoeningen.',

  'who_can_join.title': 'Wie kan aan het onderzoek deelnemen?',
  'who_can_join.content': 'U kunt aan dit onderzoek deelnemen als:',
  'who_can_join.list_item_1': 'U 18 jaar of ouder bent.',
  'who_can_join.list_item_2':
    'U tussen week 10 en week 14 van uw zwangerschap een bezoek gepland heeft aan een deelnemende medische instelling.',
  'who_can_join.list_item_3': 'U geen FNAIT heeft gehad bij een vorige zwangerschap.',

  'what_do_i_do.title': 'Wat wordt er van mij verwacht als onderdeel van het onderzoek?',
  'what_do_i_do.card_1':
    'Ten eerste ondertekent u <b>een geïnformeerd toestemmingsformulier<b>, waarin u alles over het onderzoek kunt teruglezen. De onderzoeker zal eventuele vragen over de informatie op het formulier beantwoorden.',
  'what_do_i_do.card_2':
    'Daarna stemt u in met de afname van een <b>bloedmonster tussen week 10 en week 14<b>. Het bloedmonster wordt op hetzelfde moment verzameld als andere door uw arts aangevraagde bloedtests. Als hieruit blijkt dat u geen verhoogd risico op FNAIT heeft, dan eindigt uw deelname aan de studie.',
  'what_do_i_do.card_3':
    'Afhankelijk van de resultaten van uw bloedtest kan men u vragen om <b>aanvullende bloedmonsters<b> af te nemen bij uw volgende zwangerschapsbezoek.',
  'what_do_i_do.card_4':
    'Er is een kleine kans dat uit het bloedonderzoek blijkt dat u een hoger risico op FNAIT heeft. Als dat zo is, heeft u <b>10 weken na de zwangerschap<b> nog een bloedtest om na te gaan of u antilichamen heeft ontwikkeld. Ook wordt dan uw zwangerschap besproken en wordt gekeken in het medisch dossier of genoteerd is hoeveel bloedplaatjes uw baby vlak na de bevalling had.',

  'will_cost_be_covered.title':
    'Worden de kosten van mijn medische zorg als onderdeel van het onderzoek vergoed?',
  'will_cost_be_covered.content': 'Er zijn geen kosten verbonden aan deelname aan dit onderzoek.',

  'who_will_see_results.title': 'Wie krijgt de testresultaten te zien?',
  'who_will_see_results.content_1':
    'De resultaten van de test zullen beschikbaar worden gesteld aan uw arts, die ze met u kan bespreken.',
  'who_will_see_results.content_2':
    'Uw identiteit en testresultaten worden vertrouwelijk behandeld in overeenstemming met de lokale wetgeving op het gebied van gegevensbescherming. Er wordt een uniek identificatiekenmerk gebruikt bij de overdracht van uw informatie aan de sponsor van het onderzoek.',

  'interested_in_learning.title': 'Wilt u meer weten over het onderzoek?',
  'interested_in_learning.take-away':
    'Neem voor meer informatie over het FNAIT Natuurlijk Beloop Onderzoek contact op met uw arts.',

  'footer.privacy_policy': 'Privacyverklaring',
  'footer.terms_of_use': 'Gebruiksvoorwaarden',
  'footer.disclaimer':
    'PPD - Rallybio FNAIT Natural History Study - Study Website - (08-Dec-2021) - Dutch (Netherlands) - V2.0',
};

export default nlNL;
