import React from 'react';
import {useTranslation} from 'react-i18next';
import imageWomanPregnantWhiteBlouseMobile from '../../assets/images/whyIsStudyDone_sm_woman.png';
import imageWomanPregnantWhiteBlouseTablet from '../../assets/images/whyIsStudyDone_md_woman.png';
import imageWomanPregnantWhiteBlouseDesktop from '../../assets/images/whyIsStudyDone_lg_woman.png';
import imageWomanPregnantWhiteBlouseLargeDesktop from '../../assets/images/whyIsStudyDone_xl_woman.png';

const WhyIsStudyDone = () => {
  const {t} = useTranslation();

  return (
    <section className="py-16 lg:py-20 xl:py-36 bg-violetLight">
      <div className="container md:flex md:flex-row md:justify-center md:items-start lg:items-center">
        <img
          className="hidden md:block md:mr-4 lg:mr-6
              md:w-pregnantWomanImageTablet
              lg:w-pregnantWomanImageDesktop
              xl:w-pregnantWomanImageLargeDesktop"
          srcSet={`
              ${imageWomanPregnantWhiteBlouseTablet} 768w,
              ${imageWomanPregnantWhiteBlouseDesktop} 1024w,
              ${imageWomanPregnantWhiteBlouseLargeDesktop} 1440w
            `}
          alt="pregnant woman in white blouse"
        />
        <div className="flex flex-col justify-center items-center">
          <h2 className="text-center font-extrabold mb-0 md:text-left md:mb-10">
            {t('why_is_study_done.title')}
          </h2>
          <img
            className="my-8 md:hidden w-pregnantWomanImageMobile"
            src={imageWomanPregnantWhiteBlouseMobile}
            alt="pregnant woman in white blouse"
          />
          <p className="mb-5 md:mb-10 text-center md:text-left">
            {t('why_is_study_done.content_1')}
          </p>
          <p className="text-center md:text-left">{t('why_is_study_done.content_2')}</p>
        </div>
      </div>
    </section>
  );
};

export default WhyIsStudyDone;
