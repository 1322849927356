import React from 'react';
import {useTranslation} from 'react-i18next';
import logoRallybio from '../../assets/images/logo_rallybio.svg';

const AppFooter = () => {
  const {t} = useTranslation();

  return (
    <footer className="container mt-2 sm:mt-6  lg:mt-8 xl:mt-10">
      <div className="flex flex-wrap justify-between items-center">
        <div className="w-logoMobile sm:w-logoTablet xl:w-logoDesktop mr-6 my-4 sm:my-0">
          <img src={logoRallybio} alt="Rallybio logo" />
        </div>
        <div className="flex justify-between md:justify-end items-center flex-grow max-w-xs py-1.5">
          <a
            className="link md:mr-16 pr-6 "
            href="https://www.jumohealth.com/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('footer.privacy_policy')}
          </a>
          <a
            className="link"
            href="https://www.jumohealth.com/terms-of-use"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('footer.terms_of_use')}
          </a>
        </div>
        {/* tailwind doesn't support pseudo elements, hence, aria-hidden="true" */}
      </div>
      <div className="w-full h-0.5 bg-violetLight mt-6" aria-hidden="true">
        {' '}
      </div>
      <p className="mt-6 paragraph--3">{t('footer.disclaimer')}</p>
    </footer>
  );
};

export default AppFooter;
