import ReactPlayer from 'react-player';

type Props = {
  embedId: string;
  loopTrigger: number;
  setLoopTrigger: (value: number) => void;
};

const VideoEmbedded = ({embedId, setLoopTrigger, loopTrigger}: Props) => {
  return (
    <div
      className={`px-6 
          m-auto 
          w-full 
          sm:w-videoMobile 
          h-videoMobile 
          md:w-videoTablet 
          md:h-videoTablet 
          lg:w-videoDesktop 
          lg:h-videoDesktop 
          xl:w-videoLargeDesktop 
          xl:h-videoLargeDesktop`}
    >
      <ReactPlayer
        width="100%"
        height="100%"
        url={`https://www.youtube.com/embed/${embedId}`}
        onEnded={() => {
          setLoopTrigger(loopTrigger);
        }}
        config={{
          youtube: {
            playerVars: {
              rel: 0,
              showinfo: 0,
              controls: 1,
              cc_load_policy: 0,
            },
          },
        }}
      />
    </div>
  );
};

export default VideoEmbedded;
