import React from 'react';
import {useTranslation} from 'react-i18next';
import imageScientistMobile from '../../assets/images/image_scientist_mobile.png';
import imageScientistTablet from '../../assets/images/image_scientist_tablet.png';
import imageScientistDesktop from '../../assets/images/image_scientist_desktop.png';
import imageScientistLargeDesktop from '../../assets/images/image_scientist_large_desktop.png';

const AppWelcome = () => {
  const {t} = useTranslation();

  return (
    <section
      style={{marginBottom: '-1px'}}
      className="pt-16 lg:pt-20 w-screen bg-violetLight bg-bottom bg-no-repeat bg-welcome-wave-mobile md:bg-welcome-wave-tablet lg:bg-welcome-wave-desktop xl:bg-welcome-wave-large-desktop"
    >
      <div className="container">
        <h1>{t('welcome.title')}</h1>
        <div className="md:flex mt-4 md:mt-8">
          <div>
            <p>{t('welcome.content')}</p>
          </div>
          <img
            className="
              ml-auto mr-0 md:mr-auto mt-16 md:m-0
              w-imageScientistMobile
              md:w-imageScientistTablet
              lg:w-imageScientistDesktop
              xl:w-imageScientistLargeDesktop"
            srcSet={`
              ${imageScientistMobile} 375w,
              ${imageScientistTablet} 768w,
              ${imageScientistDesktop} 1024w,
              ${imageScientistLargeDesktop} 1440w
            `}
            alt="scientist looking through microscope"
          />
        </div>
      </div>
    </section>
  );
};

export default AppWelcome;
