import React from 'react';
import {useTranslation} from 'react-i18next';
import imageWomanPregnantBlackTeeMobile from '../../assets/images/whoWillSeeResults_sm_woman.png';
import imageWomanPregnantBlackTeeTablet from '../../assets/images/whoWillSeeResults_md_woman.png';
import imageWomanPregnantBlackTeeDesktop from '../../assets/images/whoWillSeeResults_lg_woman.png';
import imageWomanPregnantBlackTeeLargeDesktop from '../../assets/images/whoWillSeeResults_xl_woman.png';

const WhoWillSeeResults = () => {
  const {t} = useTranslation();

  return (
    <section className="py-16 lg:py-20 xl:py-36 bg-violetLight">
      <div className="container flex flex-row items-center justify-center">
        <img
          className="hidden md:block md:mr-4 lg:mr-6
              md:w-pregnantWomanImageTablet
              lg:w-pregnantWomanImageDesktop
              xl:w-pregnantWomanImageLargeDesktop"
          srcSet={`
              ${imageWomanPregnantBlackTeeTablet} 768w,
              ${imageWomanPregnantBlackTeeDesktop} 1024w,
              ${imageWomanPregnantBlackTeeLargeDesktop} 1440w
            `}
          alt="pregnant woman in white blouse"
        />
        <div className="ml-0 flex flex-col items-center md:ml-16 md:justify-center md:items-start">
          <h2 className="text-center font-extrabold mb-0 md:text-left md:mb-10">
            {t('who_will_see_results.title')}
          </h2>
          <img
            className="my-8 md:hidden w-pregnantWomanImageMobile"
            src={imageWomanPregnantBlackTeeMobile}
            alt="pregnant woman in grey shirt"
          />
          <p className="mb-5 md:mb-10 text-center md:text-left">
            {t('who_will_see_results.content_1')}
          </p>
          <p className="text-center md:text-left">{t('who_will_see_results.content_2')}</p>
        </div>
      </div>
    </section>
  );
};

export default WhoWillSeeResults;
