const enUS = {
  'welcome.title': 'Welcome to the FNAIT Natural History Study',
  'welcome.content':
    'The FNAIT Natural History Study is a research study to help learn more about Fetal and Neonatal Alloimmune Thrombocytopenia (FNAIT).',
  'welcome.cta': 'Learn more about FNAIT',
  'welcome.cta_url': '1KDD_KtTilc',

  'what_is_study.title': 'What is the FNAIT Natural History Study?',
  'what_is_study.content':
    'The FNAIT Natural History Study is being conducted to learn more about FNAIT, a rare condition that affects developing babies. FNAIT happens when the mother’s immune system attacks her baby’s platelets, the blood cells that help stop bleeding. Doctors think this happens to 1 in 1000 babies, but the real number is unknown because more research is needed.',
  'what_is_study.take-away':
    'Being in the study is voluntary and you can leave the study at any time. Your future medical care with your doctor or institution will not be affected by your decision to join or leave the study.',

  'understanding_study.title': 'Understanding the FNAIT Natural History Study',
  'understanding_study.video_id': 'qon8CI2Y8EA',

  'why_is_study_done.title': 'Why is this study being done?',
  'why_is_study_done.content_1':
    'The study is part of a research effort towards a future treatment to prevent mothers and babies from being affected by FNAIT.',
  'why_is_study_done.content_2':
    'This study is sponsored by Rallybio, a biopharmaceutical company committed to identifying and accelerating the development of life-transforming therapies for patients with severe and rare disorders.',

  'who_can_join.title': 'Who can join the study?',
  'who_can_join.content': 'You can join this study if:',
  'who_can_join.list_item_1': 'You are 18 years of age or older.',
  'who_can_join.list_item_2':
    'You are having a visit between weeks 10-14 of your pregnancy at a participating medical institution.',
  'who_can_join.list_item_3': 'You have not had FNAIT with a past pregnancy. ',

  'what_do_i_do.title': 'What do I have to do as part of the study?',
  'what_do_i_do.card_1':
    'First, you would sign <b>an informed consent form<b>, which will tell you about the study. The study doctor will answer any questions you may have about the information in the form.',
  'what_do_i_do.card_2':
    'Then you would allow <b>a blood sample<b> to be taken for the study between Weeks 10 and 14 of your pregnancy.',
  'what_do_i_do.card_3':
    'Depending on the results of your blood test, you may be asked asked to provide <b>additional blood samples<b> at your next visit during the pregnancy and possibly 10 weeks after your pregnancy.',
  'what_do_i_do.card_4':
    'You would also allow <b>information about your pregnancy<b> to be shared with the researchers. This includes the results of the blood test, the outcome of your pregnancy, and if your baby had low platelet levels in their bloodwork done at birth.',

  'will_cost_be_covered.title':
    'Will the costs of my medical care be covered as part of the study?',
  'will_cost_be_covered.content': 'There are no costs to you to participate in this study.',

  'who_will_see_results.title': 'Who will see the test results?',
  'who_will_see_results.content_1':
    'The results of the test will be made available to your doctor, who will be able to discuss them with you. ',
  'who_will_see_results.content_2':
    'Your identity and test results will be kept confidential according to local data protection law. A unique identifier will be used when submitting your information to the study sponsor.',

  'interested_in_learning.title': 'Interested in learning more about the study?',
  'interested_in_learning.take-away':
    'For more information about the FNAIT Natural History Study, talk to your doctor. ',

  'footer.privacy_policy': 'Privacy Policy',
  'footer.terms_of_use': 'Terms of Use',
  'footer.disclaimer':
    'PPD - Rallybio FNAIT Natural History Study -  Study Website - (08-Dec-2021) - English (Master) - V2.0',
};

export default enUS;
