import React from 'react';
import {useTranslation} from 'react-i18next';

const WhatIsStudy = () => {
  const {t} = useTranslation();

  return (
    <section className={'bg-violetDark pb-10 md:pb-12 lg:pb-16 xl:pb-24'}>
      <div className="container flex flex-col items-start justify-center">
        <h2 className="text-left text-white font-extrabold mb-10 mt-4 md:mt-2">
          {t('what_is_study.title')}
        </h2>
        <p className="text-white pb-12 lg:pb-16 rounded-3xl text-left">
          {t('what_is_study.content')}
        </p>
        <p className="bg-white py-4 md:py-8 px-5 lg:px-6 rounded-3xl paragraph--2 text-center">
          {t('what_is_study.take-away')}
        </p>
      </div>
    </section>
  );
};

export default WhatIsStudy;
