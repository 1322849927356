interface Props {
  contents: {icon: string; text: string}[];
}

const CardsList = ({contents}: Props) => {
  return (
    <div className="flex flex-col justify-center items-center">
      {contents.map((content, index) => {
        const image = require(`../assets/images/${content.icon}`).default;
        const findTextsBold = content.text.split('<b>');

        return (
          <div
            key={`card${index}`}
            className="rounded-3xl mt-12  bg-white flex justify-center items-center"
          >
            <div className="flex flex-col md:flex-row justify-center items-center py-6 px-3.5 md:px-10 ld:px-14 xl:px-16">
              <img
                src={image}
                className="pb-8 md:pr-4 lg:pr-10 xl:pr-8 md:pb-0 w-20 md:w-24 lg:w-32 xl:w-36"
                alt={findTextsBold[1]}
              />
              <p className="text-center md:text-left">
                {findTextsBold.map((text, index) => {
                  return index % 2 !== 0 ? (
                    <span key={`cardBold${index}`} className="font-extrabold">
                      {text}
                    </span>
                  ) : (
                    text
                  );
                })}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CardsList;
