import enUS from './enUS';

const deDE = {
  ...enUS,
  'welcome.title': 'Herzlich willkommen bei der FNAIT, Studie des natürlichen Krankheitsverlaufs',
  'welcome.content':
    'Die FNAIT, Studie des natürlichen Krankheitsverlaufs, ist eine klinische Studie, deren Ziel es ist, mehr über Fetale und neonatale Alloimmun-Thrombozytopenie (FNAIT) zu erfahren.',
  'welcome.cta': 'Erfahren Sie mehr über FNAIT',

  'what_is_study.title': 'Was ist die FNAIT, Studie des natürlichen Krankheitsverlaufs?',
  'what_is_study.content':
    'Die FNAIT, Studie des natürlichen Krankheitsverlaufs wird durchgeführt, um neue Erkenntnisse über FNAIT zu gewinnen, eine seltene Krankheit, die die Entwicklung von Babys beeinträchtigt. FNAIT tritt auf, wenn das Immunsystem der Mutter die Blutplättchen (die Blutkörperchen, die helfen, Blutungen zu stoppen) ihres Babys angreift. Ärzte gehen davon aus, dass dies bei 1 von 1.000 Babys passiert. Die tatsächliche Zahl ist jedoch unbekannt, da zusätzliche Forschung erforderlich ist.',
  'what_is_study.take-away':
    'Die Wahl zur Teilnahme an der Studie liegt bei Ihnen, und Sie können die Studie jederzeit abbrechen. Ihre zukünftige medizinische Versorgung durch Ihren Arzt oder die Einrichtung wird durch Ihre Entscheidung, an der Studie teilzunehmen oder diese abzubrechen, nicht beeinträchtigt.',

  'understanding_study.title': 'Verständnis der FNAIT, Studie des natürlichen Krankheitsverlaufs',
  'understanding_study.video_id': 'hiwViiGBV4E',

  'why_is_study_done.title': 'Warum wird diese Studie durchgeführt?',
  'why_is_study_done.content_1':
    'Die Studie ist Teil eines Forschungsvorhabens für eine zukünftige Behandlung, die verhindern soll, dass Mütter und Babys von FNAIT betroffen sind.',
  'why_is_study_done.content_2':
    'Der Sponsor dieser Studie ist Rallybio, ein biopharmazeutisches Unternehmen, das sich dem Entdecken und der beschleunigten Entwicklung von lebensverändernden Therapien für Patienten, die an schweren und seltenen Erkrankungen leiden, widmet.',

  'who_can_join.title': 'Wer kann an der Studie teilnehmen?',
  'who_can_join.content': 'Sie können an der Studie teilnehmen, wenn:',
  'who_can_join.list_item_1': 'Sie 18 Jahre oder älter sind.',
  'who_can_join.list_item_2':
    'Sie einen Besuchstermin zwischen der 10. und 14. Woche Ihrer Schwangerschaft bei einer teilnehmenden medizinischen Einrichtung haben.',
  'who_can_join.list_item_3':
    'Sie während einer vorherigen Schwangerschaft kein FNAIT gehabt haben.',

  'what_do_i_do.title': 'Was muss ich im Rahmen der Studie tun?',
  'what_do_i_do.card_1':
    'Zunächst unterschreiben Sie eine <b>Patienteninformation und Einwilligungserklärung<b>, mit der Sie über die Studie informiert werden. Ihr Studienarzt wird Ihnen alle Fragen zu den in dem Formular enthaltenen Informationen beantworten.',
  'what_do_i_do.card_2':
    'Anschließend gestatten Sie, dass zwischen der 10. und 14. Woche Ihrer Schwangerschaft <b>eine Blutprobe<b> entnommen wird.',
  'what_do_i_do.card_3':
    'Abhängig von den Ergebnissen werden Sie möglicherweise gebeten, bei Ihrem nächsten Besuchstermin während der Schwangerschaft und möglicherweise 10 Wochen nach Ihrer Schwangerschaft <b>zusätzliche Blutproben<b> abzugeben. ',
  'what_do_i_do.card_4':
    'Ebenfalls gestatten Sie, dass <b>Informationen im Zusammenhang mit Ihrer Schwangerschaft<b> an die Forscher weitergegeben werden. Hierzu zählen die Ergebnisse des Bluttests, der Ausgang Ihrer Schwangerschaft und ob Ihr Baby bei der Geburt niedrige Thrombozytenwerte im Blut hatte.',

  'will_cost_be_covered.title':
    'Werden die Kosten meiner medizinischen Betreuung im Rahmen der Studie übernommen?',
  'will_cost_be_covered.content':
    'Für Ihre Teilnahme an der Studie haben Sie keinerlei Kosten zu tragen.',

  'who_will_see_results.title': 'Wer wird die Testergebnisse sehen?',
  'who_will_see_results.content_1':
    'Die Ergebnisse des Tests werden Ihrem Arzt zur Verfügung gestellt, der sie mit Ihnen besprechen kann.',
  'who_will_see_results.content_2':
    'Ihre Identität und Ihre Testergebnisse werden in Übereinstimmung mit den lokalen Datenschutzgesetzen vertraulich behandelt. Bei der Übermittlung Ihrer Informationen an den Sponsor der Studie wird eine eindeutige Kennung verwendet.',

  'interested_in_learning.title': 'Interessieren Sie sich dafür, mehr über die Studie zu erfahren?',
  'interested_in_learning.take-away':
    'Für weitere Informationen über die FNAIT, Studie des natürlichen Krankheitsverlaufs sprechen Sie mit Ihrem Arzt.',

  'footer.privacy_policy': 'Datenschutzrichtlinie',
  'footer.terms_of_use': 'Nutzungsbedingungen',
  'footer.disclaimer':
    'PPD - Rallybio FNAIT Natural History Study - Study Website - (08-Dec-2021) - German (Germany) - V2.0',
};

export default deDE;
