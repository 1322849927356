import React from 'react';
import logoRallybio from '../../assets/images/logo_rallybio.svg';

const AppHeader = () => {
  return (
    <header className="bg-violetLight">
      <div className="container pt-4 md:pt-6 xl:pt-9 flex justify-end">
        <img
          className="w-logoMobile md:w-logoTablet lg:w-logoDesktop xl:w-logoLargeDesktop"
          src={logoRallybio}
          alt="rallybio logo"
        />
      </div>
    </header>
  );
};

export default AppHeader;
