import enUS from './enUS';

const esUS = {
  ...enUS,
  'welcome.title': 'Le damos la bienvenida al Estudio de evolución natural de la FNAIT',
  'welcome.content':
    'El Estudio de evolución natural de la FNAIT es un estudio de investigación que nos permite obtener más información sobre la trombocitopenia aloinmune fetal y neonatal (FNAIT).',
  'welcome.cta': 'Más información sobre la FNAIT',

  'what_is_study.title': '¿Qué es el Estudio de evolución natural de la FNAIT?',
  'what_is_study.content':
    'El Estudio de evolución natural de la FNAIT se está llevando a cabo para obtener más información sobre la FNAIT, una enfermedad minoritaria que afecta a los bebés en desarrollo. La FNAIT se produce cuando el sistema inmunitario de la madre ataca las plaquetas del bebé, los glóbulos sanguíneos que ayudan a detener las hemorragias. Los médicos creen que esto le sucede a 1 de cada 1000 bebés, pero la cifra real se desconoce porque se necesitan más investigaciones.',
  'what_is_study.take-away':
    'La participación es voluntaria y puede abandonar el estudio en cualquier momento. No se afectará su futura atención médica con su médico o centro por su decisión de participar en el estudio o de abandonarlo.',

  'understanding_study.title': 'Comprensión del estudio Estudio de evolución natural de la FNAIT',
  'understanding_study.video_id': 'JPyVImqYYCo',

  'why_is_study_done.title': '¿Por qué se realiza este estudio?',
  'why_is_study_done.content_1':
    'El estudio forma parte de un esfuerzo de investigación hacia un futuro tratamiento para evitar que la FNAIT afecte a las madres y los bebés.',
  'why_is_study_done.content_2':
    'El patrocinador de este estudio es Rallybio, una empresa biofarmacéutica comprometida con la identificación y aceleración de la creación de tratamientos que transformen la vida de los pacientes con trastornos graves y minoritarios.',

  'who_can_join.title': '¿Quiénes pueden participar en el estudio?',
  'who_can_join.content':
    'Puede participar en este estudio si cumple con los siguientes requisitos:',
  'who_can_join.list_item_1': 'Tiene 18 años o más.',
  'who_can_join.list_item_2':
    'Tiene programada una visita entre las semanas 10 y 14 de su embarazo en un centro médico participante.',
  'who_can_join.list_item_3': 'No tuvo FNAIT con un embarazo anterior.',

  'what_do_i_do.title': '¿Qué tengo que hacer si participo en el estudio?',
  'what_do_i_do.card_1':
    'En primer lugar, firmará <b>un formulario de consentimiento informado<b>, en el que se le explicará lo que necesita saber del estudio. El médico del estudio responderá todas las preguntas que tenga sobre la información del formulario.',
  'what_do_i_do.card_2':
    'A continuación, deberá someterse a la extracción de <b>una muestra de sangre<b> para el estudio entre las semanas 10 y 14 del embarazo.',
  'what_do_i_do.card_3':
    'Según los resultados del análisis de sangre, se le hará <b>otro análisis de sangre<b> 10 semanas después del nacimiento del bebé para comprobar si desarrolló anticuerpos.',
  'what_do_i_do.card_4':
    'También deberá permitir que se comparta <b>información sobre su embarazo<b> con los investigadores. Esto incluye los resultados del análisis de sangre, el resultado de su embarazo y si su bebé tenía concentraciones bajas de plaquetas en su análisis de sangre realizado al nacer.',

  'will_cost_be_covered.title':
    '¿Se cubrirán los costos de mi atención médica como parte del estudio?',
  'will_cost_be_covered.content': 'Participar en este estudio no tiene ningún costo.',

  'who_will_see_results.title': '¿Quiénes verán los resultados del análisis?',
  'who_will_see_results.content_1':
    'El médico podrá ver los resultados del análisis y podrá comentarlos con usted.',
  'who_will_see_results.content_2':
    'Su identidad y los resultados de los análisis se mantendrán confidenciales de acuerdo con la ley local de protección de datos. Para enviar su información al patrocinador del estudio, se utilizará un identificador único.',

  'interested_in_learning.title': '¿Le interesa conocer más sobre el estudio?',
  'interested_in_learning.take-away':
    'Para obtener más información sobre el Estudio de evolución natural de la FNAIT, hable con el médico.',

  'footer.privacy_policy': 'Política de privacidad',
  'footer.terms_of_use': 'Términos de uso',
  'footer.disclaimer':
    'PPD - Rallybio FNAIT Natural History Study - Study Website - (08-Dec-2021) - Spanish (US) - V2.0',
};

export default esUS;
