import React from 'react';
import {useTranslation} from 'react-i18next';
import CardsList from '../../components/CardsList';

const WhatDoIDo = () => {
  const {t} = useTranslation();

  const contents = [
    {icon: 'whatDoIDo_icon_1.svg', text: t('what_do_i_do.card_1')},
    {icon: 'whatDoIDo_icon_2.svg', text: t('what_do_i_do.card_2')},
    {icon: 'whatDoIDo_icon_3.svg', text: t('what_do_i_do.card_3')},
    {icon: 'whatDoIDo_icon_4.svg', text: t('what_do_i_do.card_4')},
  ];

  return (
    <section className="py-16 lg:py-20 xl:py-36 bg-violetLight">
      <div className="container flex flex-col items-center justify-center">
        <h2 className="text-center font-extrabold md:text-left">{t('what_do_i_do.title')}</h2>
        <CardsList contents={contents} />
      </div>
    </section>
  );
};

export default WhatDoIDo;
