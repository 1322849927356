import React from 'react';
import AppFooter from './modules/AppFooter';
import AppWelcome from './modules/AppWelcome';
import UnderstandingStudy from './modules/UnderstandingStudy';
import WhatIsStudy from './modules/WhatIsStudy';
import WhatDoIDo from './modules/WhatDoIDo';
import WhoCanJoin from './modules/WhoCanJoin';
import WhoWillSeeResults from './modules/WhoWillSeeResults';
import WhyIsStudyDone from './modules/WhyIsStudyDone';
import WillCostsBeCovered from './modules/WillCostsBeCovered';
import AppHeader from './modules/AppHeader';
import InterestedInLearning from './modules/InsterestedInLearning';

function App() {
  return (
    <div>
      <AppHeader />
      <main>
        <AppWelcome />
        <WhatIsStudy />
        <UnderstandingStudy />
        <WhyIsStudyDone />
        <WhoCanJoin />
        <WhatDoIDo />
        <WillCostsBeCovered />
        <WhoWillSeeResults />
        <InterestedInLearning />
      </main>
      <AppFooter />
    </div>
  );
}

export default App;
