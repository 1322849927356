import enUS from './enUS';

const svSE = {
  ...enUS,
  'welcome.title': 'Välkommen till FNAIT naturhistorisk undersökning',
  'welcome.content':
    'FNAIT naturhistorisk undersökning är en forskningsstudie för att bidra till ökad kunskap om fetal och neonatal alloimmun trombocytopeni (fetal and Neonatal Alloimmune Thrombocytopenia, FNAIT).',
  'welcome.cta': 'Få mer information om FNAIT',

  'what_is_study.title': 'Vad är FNAIT naturhistorisk undersökning?',
  'what_is_study.content':
    'FNAIT naturhistorisk undersökning genomförs för att ta reda på mer om FNAIT, ett sällsynt tillstånd som drabbar foster i utveckling. FNAIT uppkommer när moderns immunsystem angriper fostrets trombocyter, de blodkroppar som hjälper till att stoppa blödning. Läkarna tror att detta händer 1 av 1 000 foster, men den verkliga siffran är okänd eftersom det behövs mer forskning.',
  'what_is_study.take-away':
    'Deltagande i studien är valfritt och du kan lämna studien när som helst. Din framtida sjukvård hos din läkare eller på din klinik kommer inte att påverkas av ditt beslut att gå med i eller lämna studien.',

  'understanding_study.title': 'Att förstå FNAIT naturhistorisk undersökning',
  'understanding_study.video_id': 'mx96SwEjf1I',

  'why_is_study_done.title': 'Varför genomförs den här studien?',
  'why_is_study_done.content_1':
    'Studien ingår i en forskningsinsats som syftar till att hitta en framtida behandling för att förhindra att mödrar och barn drabbas av FNAIT.',
  'why_is_study_done.content_2':
    'Denna studie sponsras av Rallybio, ett biofarmaceutiskt företag som gjort ett åtagande att identifiera och påskynda utvecklingen av livsförändrande terapier för patienter med svåra och sällsynta sjukdomar.',

  'who_can_join.title': 'Vem kan gå med i studien?',
  'who_can_join.content': 'Du kan gå med i studien om:',
  'who_can_join.list_item_1': 'Du är 18 år eller äldre.',
  'who_can_join.list_item_2':
    'Du har ett besök mellan veckorna 10‑14 av din graviditet vid en sjukvårdsinrättning.',
  'who_can_join.list_item_3': 'Du har inte haft FNAIT vid en tidigare graviditet.',

  'what_do_i_do.title': 'Vad behöver jag göra som en del av studien?',
  'what_do_i_do.card_1':
    'Först behöver du underteckna <b>ett formulär för informerat samtycke<b>, där du får veta allt om studien. Din studieläkare kommer att besvara alla frågor du har om informationen i formuläret.',
  'what_do_i_do.card_2':
    'Sedan behöver du ge ditt tillstånd till att <b>ett blodprov<b> tas mellan Veckorna 10 och 14. Blodprovet tas samtidigt med andra blodprover som din läkare beställer.',
  'what_do_i_do.card_3':
    'Beroende på resultatet av ditt blodprov får du ta <b>ett blodprov till<b> 10 veckor efter att ditt barn föds, för att kontrollera om du har utvecklat antikroppar.',
  'what_do_i_do.card_4':
    'Du måste även tillåta att <b>information om din graviditet<b> delas med forskarna. Detta inbegriper resultatet av blodprovet, utfallet av din graviditet och om ditt barn hade låga trombocytnivåer i de blodprov som togs vid födseln.',

  'will_cost_be_covered.title':
    'Kommer kostnaderna för min sjukvård att täckas som en del av studien?',
  'will_cost_be_covered.content': 'Det innebär ingen kostnad för dig att delta i denna studie.',

  'who_will_see_results.title': 'Vem kommer att se provresultaten?',
  'who_will_see_results.content_1':
    'Resultaten av provet kommer att göras tillgängligt för din läkare, som kommer att kunna prata om dem med dig.',
  'who_will_see_results.content_2':
    'Din identitet och dina testresultat kommer att hållas under sekretess i enlighet med lokal dataskyddslagstiftning. En unik identifierare kommer att användas när din information skickas till studiens sponsor.',

  'interested_in_learning.title': 'Är du intresserad av att få veta mer om studien?',
  'interested_in_learning.take-away':
    'För mer information om FNAIT naturhistorisk undersökning, prata med din läkare.',

  'footer.privacy_policy': 'Integritetspolicy',
  'footer.terms_of_use': 'Användarvillkor',
  'footer.disclaimer':
    'PPD - Rallybio FNAIT Natural History Study - Study Website - (08-Dec-2021) - Swedish (Sweden) - V2.0',
};

export default svSE;
