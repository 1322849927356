import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import enUS from './locales/enUS';
import esUS from './locales/esUS';
import deDE from './locales/deDE';
import noNO from './locales/noNO';
import svSE from './locales/svSE';
import nlNL from './locales/nlNL';
import enUK from './locales/enUK';

// the translations
const resources = {
  'en-US': {
    translation: enUS,
  },
  'es-US': {
    translation: esUS,
  },
  'de-DE': {
    translation: deDE,
  },
  'no-NO': {
    translation: noNO,
  },
  'sv-SE': {
    translation: svSE,
  },
  'nl-NL': {
    translation: nlNL,
  },
  'en-UK': {
    translation: enUK,
  },
};

const options = {
  order: ['querystring', 'path', 'navigator'],
  lookupQuerystring: 'lng',
  lookupFromPathIndex: 0,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(detector)
  .init({
    detection: options,
    resources,
    fallbackLng: 'en-US',
    keySeparator: false, // we do not use keys in form messages.welcome
    supportedLngs: ['en-US', 'es-US', 'de-DE', 'no-NO', 'sv-SE', 'nl-NL', 'en-UK'],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
